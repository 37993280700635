<template>
	<section class="prod_tools">
		<component :is="$store.state.mainComponent" v-bind="$store.state.mainComponentParams"/>
	</section>
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
	name: "Main",
	data(){
		return {};
	},
	components: {
		JobSelection: defineAsyncComponent(() => import('./JobSelection.vue')),
		ProdScanJob: defineAsyncComponent(() => import('./ProdScanJob.vue')),
		EscalationView: defineAsyncComponent(() => import('./EscalationView.vue')),
	},
}
</script>

<style scoped>

</style>