/**
 * The `ew` variable is an object that contains methods related to the `ew` functionality.
 *
 * This object encapsulates a set of methods for interacting with the `ew` feature,
 * currently containing a single method that references the global `ew` object from the `window` object.
 *
 * @property {Object} methods - The collection of methods related to `ew`.
 * @property {Object} methods.ew - A reference to the global `ew` object from the `window`.
 */
const ew = {
	methods: {
		ew: window.ew,
	},
};

export default ew;